import {REQUEST_START, REQUEST_FINISH,AUTH_COMPLETE } from '../actions/common';
import ls from 'local-storage'

const notification = (state = {
  show:false,
  title :'',
  type: 'success',
  body:''
} , action) => {
  switch (action.type) {

      case 'NOTIFICATION_SHOW':


        return Object.assign({}, state, {

          show : true,
          title :action.data.title,
          type: action.data.type,
          body:action.data.body
        
        });
      case 'NOTIFICATION_HIDE':

        return Object.assign({}, state, {

          show : false
        
        });
    default:
      return state
  }
}
export default notification