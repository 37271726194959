import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

// import DataProduct from "../component/lists/Products";
// import FilterModelProducts from "../component/filters/Products";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import { PageHeader, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import { profile, updateProfile } from "../../actions/auth";

const { Option } = Select;

function User({ data, getData, updateData, REFRESH }) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [formValues, updateFormValues] = useState({
    role_type: 2,
    isFormEdit: true,    
  });

  const history = useHistory();

  const updateDrawer = () => {
    toggleDrawer(!showDrawer);
  };

  const [form] = Form.useForm();


  useEffect(() => {
  
    if(data){
      form.setFieldsValue({...formValues, ...data})
    }
  }, [data]);


  useEffect(() => {
    form.resetFields();
    getData({ query: "" });
  }, [REFRESH]);

  const onFormSubmit = (values) => {
    console.log("form data", values);

    if (values.isFormEdit) updateData({ name :  values.name, password : values.password});
  };

  const onUpdateItem = (data, updates) => {
    updateData({ ...data, ...updates });
  };

  const onActionItem = (data) => {
    updateFormValues({ ...data, isFormEdit: true, uniId: data.user_id });
    form.setFieldsValue({ ...data, isFormEdit: true, uniId: data.user_id });
    updateDrawer();

    // updateProduct(data.product_id, {...data, is_private: !data.is_private});
  };

  const validateForm = (_, values) => {
    console.log("validate", values);

    if (formValues.isFormEdit) {
      return Promise.resolve();
    } else if (!values || values === "" || values.length < 6) {
      return Promise.reject(
        new Error("Password is mandatory and need to be greater than 6 digits")
      );
    } else {
      return Promise.resolve();
    }
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Update Profile"
        className="site-page-header"
        extra={[
          <Button
            onClick={() => {
              form.submit();
            }}
            type="primary"
          >
            UPDATE
          </Button>,
        ]}
      ></PageHeader>
      <Row gutter={[16, 16]} justify="center">
        <Col span={6}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFormSubmit}
            values={formValues}
            hideRequiredMark
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="isFormEdit" hidden={true}></Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="uniId" hidden={true}></Form.Item>
              </Col>
          

              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please enter user name" },
                  ]}
                >
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="mobile"
                  label="Mobile"
                  
                  rules={[
                    { required: true, message: "Please enter user mobile" },
                  ]}
                >
                  <Input disabled placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "Please enter user email" },
                  ]}
                >
                  <Input disabled placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              {
                <Col span={24}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ validator: validateForm }]}
                  >
                    <Input placeholder="Please enter user password" />
                  </Form.Item>
                </Col>
              }
              <Col span={48}>
                <Form.Item>
                  <Button
                    onClick={() => {
                      form.submit();
                    }}
                    type="primary"
                  >
                    UPDATE
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.auth.profile,
  REFRESH: state.levent.USERS_REFRESH,
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(profile()),
  updateData: (data) => dispatch(updateProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
