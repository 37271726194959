
import ls from 'local-storage'


const auth = (state = {isAuth: false, user:{}, access_token:''}, action) => {
  switch (action.type) {

    case 'AUTH_SUCCESS':



      localStorage.setItem('access_token', `${action.data.token_type} ${action.data.access_token}`);

        return {...state, isAuth: true, user : action.data, access_token : action.data.access_token};

    case 'AUTH_LOGOUT':



      localStorage.removeItem('access_token');

        return {...state, isAuth: false};

      case 'AUTH_FAILED':
        localStorage.removeItem('access_token');

        return {...state, isAuth: false, user : null, access_token : ''};
  
    default:
      return state
  }
}
export default auth