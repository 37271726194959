import {REQUEST_START, REQUEST_FINISH,AUTH_COMPLETE } from '../actions/common';
import ls from 'local-storage'

const levent = (state = {} , action) => {

  switch (action.type) {


    case 'FIRE_EVENT':


      return {
        ...state,
        [action.data.event]:  Date.now()
      }

    default:
      return state
  }
}
export default levent