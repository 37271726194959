import React, { useState, useEffect } from "react";

import "./App.css";
import { connect } from "react-redux";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { refresh } from "./actions/auth";

import CorePage from "./pages/CorePage";
import Login from "./pages/Login";

function App({ isAuth, refreshToken }) {
  useEffect(() => {
    refreshToken();
  }, []);

  // isAuth =true;

  //  if (isAuth === false) {
  //    console.log("inside this");
  //   return <Redirect to='/login' />
  // }else{
  //   console.log(isAuth);
  // }

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return isAuth ? <Redirect to="/admin" /> : <Redirect to="/login" />;
          }}
        />

        <Route exact path="/login">
           <Login />
         </Route>

        <Route
          path="/admin"
          render={() => {
            return isAuth ? <CorePage /> : <Redirect to="/login" />;
          }}
        />

        <Route
          path="*"
          exact={true}
          render={() => {
            return isAuth ? <Redirect to="/admin" /> : <Redirect to="/login" />;
          }}
        />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});

const mapDispatchToProps = (dispatch) => ({
  refreshToken: () => dispatch(refresh()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
