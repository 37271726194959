export const getStateCountry = (lookup, country_id, state_id) => {
  let country_name = "";
  let state_name = "";
  let countries = lookup.filter((country) => country.id === country_id);

  console.log(countries, country_id, state_id);

  if (countries.length > 0) {
    country_name = countries[0].name;

    let states = countries[0].children.filter((state) => state.id === state_id);

    if (states.length > 0) {
      state_name = states[0].name;
    }
  }

  return `${state_name}, ${country_name}`;
};

export const filterCountryState = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};

export const objectToQueryParams = (obj) => {
  const queryParams = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      );
    }
  }

  if (queryParams.length > 0) {
    return `?${queryParams.join("&")}`;
  }

  return "";
};
